<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Instellingen</h1>
      </template>
      <template v-slot:right> </template>
    </sb-page-header>

    <i-form ref="schooldistrict" :rules="validationRules" :label-width="160">
      <sb-divider title="Schooldistrict seats" />
      <sb-change-seats
        v-if="data && data.invoiceSelf"
        :organisation-id="data.id"
        :initial-seats="data.seats"
        @change="$emit('refetch')"
      />
      <p v-if="!data.invoiceSelf">
        De seats zijn gedefinieerd in onderliggende scholen van dit
        schooldistrict.
      </p>
      <sb-spacer height="20" />
      <sb-divider title="Schooldistrict status" />
      <sb-set-closing-date
        :organisation-id="data.id"
        :initial-date="data.closingDate"
        @change="$emit('refetch')"
      />
      <form-item label="Status aanpassen:">
        <sb-activate-organisation
          :id="data.id"
          label="Schooldistrict"
          :active="data.active"
        />
      </form-item>
    </i-form>

    <sb-spacer height="20" />
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbPageHeader from '@/components/SbPageHeader';
import SbChangeSeats from '@/components/SbChangeSeats.vue';
import SbActivateOrganisation from '@/components/SbActivateOrganisation.vue';
import SbSetClosingDate from '@/components/SbSetClosingDate';

export default {
  components: {
    SbDivider,
    SbPageHeader,
    SbChangeSeats,
    SbSetClosingDate,
    SbActivateOrganisation,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      validationRules: {},
    };
  },
};
</script>

<style></style>
