<template>
  <div>
    <sb-gql-table
      ref="table"
      :config="tableConfig"
      :query-options="queryOptions"
      :data-path="tableDataPath"
      @loading="loading = $event"
      @change="schools = $event"
      @row-action-managers="viewManagers"
      @cell-click-name="viewDistrict"
    >
    </sb-gql-table>

    <div v-if="!schools || (!schools.length && loading)">
      Geen scholen gevonden
    </div>
  </div>
</template>

<script>
import ChildOrganisations from '@/graphql/queries/ChildOrganisations.gql';
import Organisations from '@/graphql/queries/Organisations.gql';
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { TableConfig } from './SbTable2/TableConfig';
import SbGqlTable from './SbTable2/SbGqlTable.vue';
import {
  DateTimeFilter,
  IntFloatFilter,
  StringFilter,
} from '@/lib/gql-filters';
import { educationTypeMixin } from '@/lib/education-type';

export default {
  components: {
    SbGqlTable,
  },

  mixins: [
    educationTypeMixin,
    nonReactiveMembersMixin((self) => {
      const tableConfig = new TableConfig({
        rowActions: self.hideActions
          ? []
          : [['managers', 'Schooldistrictbeheerders']],
        columns: [
          {
            key: 'name',
            header: 'Naam',
            fixed: 'left',
            width: 250,
            action: true,
            filter: 'search',
            sortable: true,
            meta: { gqlFilter: StringFilter },
          },
          {
            key: 'address',
            header: 'Plaats',
            data: (row) => row.address?.city,
          },
          {
            key: 'seats',
            header: 'Aantal licenties',
            width: 300,
            filter: 'compare',
            // sortable: true,
            meta: { gqlFilter: IntFloatFilter },
            data: (row) => (row.invoiceSelf ? row.seats ?? 0 : 'n.v.t.'),
          },
          {
            key: 'takenSeats',
            header: 'Gebruikte licenties',
            width: 300,
            // sortable: true,
            // filter: 'compare',
            // meta: { gqlFilter: IntFloatFilter },
            data: (row) => (row.invoiceSelf ? row.takenSeats ?? 0 : 'n.v.t.'),
          },
          {
            key: 'managers',
            header: 'Beheerder',
            data: (row) => row.managers?.edges?.[0]?.node.fullName,
          },
          {
            key: 'parentOrganisation',
            header: 'Schooldistrict',
            data: (row) => row.parentOrganisation?.name,
          },
          {
            key: 'contact',
            header: 'E-mailadres',
            data: (row) => row.contact?.email,
          },
          {
            key: 'closingDate',
            header: 'Sluitingsdatum',
            width: 300,
            // sortable: true,
            filter: 'date',
            meta: { gqlFilter: DateTimeFilter },
            data: (row) =>
              row.closingDate
                ? new Date(row.closingDate).toLocaleDateString()
                : '-',
          },
          {
            key: 'active',
            header: 'Actief',
            filter: 'boolean',
            checked: true,
            data: (row) => (row.active ? 'Ja' : 'Nee'),
          },
        ],
      });

      return { tableConfig };
    }),
  ],

  props: {
    organisationId: { type: String, default: undefined },
    hideActions: { type: Boolean, default: false },
  },

  data() {
    return { schools: undefined, loading: false };
  },

  computed: {
    tableDataPath() {
      return this.organisationId
        ? 'getOrganisationById.childOrganisations'
        : 'organisations';
    },
  },

  methods: {
    queryOptions({
      first,
      last,
      after,
      before,
      filter,
      filters,
      orderBy,
      pagination,
    }) {
      const query = this.organisationId ? ChildOrganisations : Organisations;
      const variables = {
        first,
        last,
        after,
        before,
        orderBy,
        id: this.organisationId,
        filter: {
          ...filter,
          active: filters.active?.checked ?? true,
          organisationType: 'SCHOOL_DISTRICT',
        },
      };

      return { query, variables };
    },

    viewDistrict({ id }) {
      this.$router.push({
        name: 'ResourceManagementSchooldistrictsSchooldistrict',
        params: { organisationId: id },
      });
    },

    viewManagers({ id }) {
      this.$router.push({
        name:
          'ResourceManagementSchooldistrictsSchooldistrictSchooldistrictManagers',
        params: { organisationId: id },
      });
    },
  },
};
</script>

<style></style>
