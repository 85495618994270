<template>
  <div>
    <!-- <Breadcrumb>
      <BreadcrumbItem>Schooldistricten</BreadcrumbItem>
    </Breadcrumb> -->

    <sb-page-header>
      <template v-slot:left>
        <h1>Schooldistricten</h1>
      </template>
      <template v-slot:right>
        <router-link
          v-if="['ADMIN', 'PARTNER_MANAGER'].includes($user.role)"
          :to="{
            name: 'ResourceManagementOrganisationAdd',
            params: { organisationType: 'schoolDistrict', manageMethod: 'add' },
          }"
        >
          <i-button type="primary" ghost>
            Schooldistrict toevoegen

            <icon type="md-add" />
          </i-button>
        </router-link>
      </template>
    </sb-page-header>

    <sb-schooldistrics-table :schooldistrict-id="schooldistrictId" />

    <sb-spacer height="200" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';

import SbSchooldistricsTable from '@/components/SbSchooldistricsTable';

export default {
  name: 'OnlineStudents',
  components: {
    SbPageHeader,
    SbSchooldistricsTable,
  },
  props: {
    schooldistrictId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss"></style>
