<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Schools</h1>
      </template>
      <template v-slot:right>
      </template>
    </sb-page-header>

    <sb-schools-table
      :hide-actions="true"
      :organisation-id="organisationId || resourceId"
    />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';

import SbSchoolsTable from '@/components/SbSchoolsTable';


export default {
  components: {
    SbPageHeader,
    SbSchoolsTable
  },
  props: {
    resourceId: {
      type: String,
      default: null
    },
    organisationId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {},
  mounted() {
    console.log('RID', this.resourceId);
  }
};
</script>

<style>

</style>
